import { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "./firebaseConfig";
import { FaShoppingCart } from "react-icons/fa";

const App = () => {
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState(0);
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "products"), (snapshot) => {
      const productsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsData);
    });

    return () => unsubscribe();
  }, []);

  const _renderButton = (item) => {
    if (item?.OnShelf) {
      return (
        <button className={`mt-4 w-full bg-grocery-orange text-white py-2 px-4 rounded-[50px] `} onClick={handleAddToCart}>
          Buy
        </button>
      );
    }

    return (
      <button disabled className="mt-4 w-full bg-red-300 text-white py-2 px-4 rounded-[50px]  ">
        No available
      </button>
    );
  };

  const _renderOverlay = (item) => {
    if (!item?.OnShelf) {
      return <div className="absolute inset-0 bg-gray-300 opacity-50"></div>;
    }
  };

  const handleAddToCart = () => {
    setIsPressed(!isPressed);
    setItems(items + 1);
  };

  console.log(products);

  return (
    <div>
      <div className="h-[40px] bg-grocery-orange flex justify-between">
        <img src="https://groceryshop.com/assets/images/common/logo.svg?150611277" alt="logo" className="h-[45px] ml-5" />
        <div className={`relative cursor-pointer items-center justify-center flex`}>
          <FaShoppingCart className="h-[30px] w-[30px] mr-5 text-white" />
          <span className=" text-white text-[14px] font-[500] rounded-full absolute top-0 right-2">{items > 0 && items}</span>
        </div>
      </div>
      <div className="container mx-auto px-4 py-10">
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {products.map((product) => (
            <div key={product.id} className="relative bg-white p-10 rounded-lg shadow-lg hover:shadow-2xl transition duration-300">
              <img src={product?.ImageUrl} alt={product?.Name} className="w-full h-40 object-contain rounded-t-lg" />
              <div className="border-t border-gray-300 mt-8"></div>
              <div className="mt-4 text-center">
                <h2 className="text-[18px] font-bold text-md truncate">{product?.Name}</h2>
                <h2 className="text-[18px] font-bold text-md truncate mb-4 text-red-700">Virtual stock: {product?.Stock}</h2>
                {_renderButton(product)}
              </div>
              {_renderOverlay(product)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;
