import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCYgSP5wOJ9DEKuDJcnzrJT2m1XMU90kQw",
  authDomain: "test-grocery-f8b45.firebaseapp.com",
  projectId: "test-grocery-f8b45",
  storageBucket: "test-grocery-f8b45.appspot.com",
  messagingSenderId: "925347874370",
  appId: "1:925347874370:web:ca6205c4e7d388ae490fdb",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
